import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { GameType } from '../../constants/availableGames';

export const useGameValidationFormError = (game: GameType) =>
	useAppSelector(
		(state) => state.gameValidation?.formError?.[game],
		shallowEqual,
	);
export const useGameValidationProfitError = (game: GameType) =>
	useAppSelector(
		(state) => state.gameValidation?.profitError?.[game],
		shallowEqual,
	);
