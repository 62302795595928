import { useCallback, useRef } from 'react';
import {
	FetchProps,
	useFetchApiPromise,
} from '@legacyApp/hooks/fetch/useFetchApi';
import { UseGetDataArrayCallback } from '@legacyApp/hooks/fetch/useGetDataArray';
import {
	generateSlotGameListUrl,
	SlotGameListQuery,
} from '../services/Slots.service';
import { normalizeSlots } from '../services/Slots.mapper';
import { Slot } from '../store/slots.types';

export const useFetchGames = () => {
	const fetchApi = useFetchApiPromise();
	const pages = useRef([]);

	const callback: UseGetDataArrayCallback<Slot, SlotGameListQuery> =
		useCallback(
			async (query: SlotGameListQuery, props?: FetchProps) => {
				const page = query.page || 1;

				return await fetchApi({
					url: generateSlotGameListUrl(query),
					disableErrorHandler: true,
					...(props || {}),
				})
					.then((response) => {
						if (page === 1) {
							pages.current = [];
						}
						pages.current[page] = normalizeSlots(
							response?.data?.list || response?.list || [],
						);
						const data = [].concat(...pages.current);
						data.shift();
						return {
							data,
							count: response?.data?.length || response?.length || 0,
						};
					})
					.catch(() => {
						return {
							data: [],
							count: 0,
						};
					});
			},
			[fetchApi],
		);

	return {
		callback,
	};
};
