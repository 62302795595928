import { LinesType, MultiplierType, MultiplierValues, Risk } from '../../types';
import { config } from '../playground/config';

export enum MultiplierColors {
	RED_DARKER = '#ff003f',
	RED_DARK = '#ff1837',
	RED = '#ff302f',
	ORANGE_DARK = '#ff4827',
	ORANGE = '#ff6020',
	ORANGE_LIGHT = '#ff7818',
	YELLOW_DARK = '#ff9010',
	YELLOW = '#ffa818',
	YELLOW_LIGHT = '#ffc000',
}

export const MultiplierColorCombinations: Record<
	number,
	Array<MultiplierColors>
> = {
	'9': [
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
	],
	'10': [
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
	],
	'11': [
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
	],
	'12': [
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
	],
	'13': [
		MultiplierColors.RED,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.RED,
	],
	'14': [
		MultiplierColors.RED,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.RED,
	],
	'15': [
		MultiplierColors.RED_DARK,
		MultiplierColors.RED,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.RED,
		MultiplierColors.RED_DARK,
	],
	'16': [
		MultiplierColors.RED_DARK,
		MultiplierColors.RED,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.RED,
		MultiplierColors.RED_DARK,
	],
	'17': [
		MultiplierColors.RED_DARKER,
		MultiplierColors.RED_DARK,
		MultiplierColors.RED,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_LIGHT,
		MultiplierColors.YELLOW,
		MultiplierColors.YELLOW_DARK,
		MultiplierColors.ORANGE_LIGHT,
		MultiplierColors.ORANGE,
		MultiplierColors.ORANGE_DARK,
		MultiplierColors.RED,
		MultiplierColors.RED_DARK,
		MultiplierColors.RED_DARKER,
	],
};

export const getMultiplier = (
	value: MultiplierValues,
	lines: LinesType,
	risk: Risk,
	payoutIndex: number,
): MultiplierType => {
	return {
		label: `${config.labels.floor}||${value}||${0}||${payoutIndex}`,
		img: `multiplier${value}`,
		value,
		lines,
		risk,
		payoutIndex,
	};
};

export const multiplyBlocksByLinesQnt: Record<
	Risk,
	Record<LinesType, Array<number>>
> = {
	[Risk.LOW]: {
		8: [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6],
		9: [5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6],
		10: [8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9],
		11: [8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4],
		12: [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10],
		13: [8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1],
		14: [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1],
		15: [15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15],
		16: [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16],
	},
	[Risk.MEDIUM]: {
		8: [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13],
		9: [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18],
		10: [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22],
		11: [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24],
		12: [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33],
		13: [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43],
		14: [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58],
		15: [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88],
		16: [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110],
	},
	[Risk.HIGH]: {
		8: [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29],
		9: [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43],
		10: [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76],
		11: [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120],
		12: [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170],
		13: [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260],
		14: [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420],
		15: [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620],
		16: [
			1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000,
		],
	},
};

export function getMultiplierByLinesQnt(
	lines: LinesType,
	risk: Risk,
): MultiplierType[] {
	return multiplyBlocksByLinesQnt[risk][lines].map(
		(value: MultiplierValues, index) =>
			getMultiplier(value, lines, risk, index),
	);
}
