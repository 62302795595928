import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { Tooltip } from '@legacyApp/client/components/tooltip';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { usePlinkoLinesSelector } from '../../../Game/store/plinko/selectors/usePlinkoLinesSelector';
import { usePlinkoRiskSelector } from '../../../Game/store/plinko/selectors/usePlinkoRiskSelector';
import transactionService from '../../../../transactions/transactionsService';
import { LinesType, Risk } from '../../types';
import { useGameValidationProfitError } from '../../../Game/store/gameValidation/gameValidation.selectors';
import { GameType } from '../../../Game/constants/availableGames';
import { getMultiplierByLinesQnt } from './multipliers';
import { MultiplierBox } from './MultiplierBox';

export const MultiplierBoxes: FC<{
	lines?: LinesType;
	risk?: Risk;
	enableAnimation?: boolean;
	onModal?: boolean;
	profitError?: string | boolean;
}> = ({ lines, risk, enableAnimation, onModal, profitError }) => {
	const stateLines = usePlinkoLinesSelector();
	const lines_ = lines || stateLines;
	const stateRisk = usePlinkoRiskSelector();
	const risk_ = risk || stateRisk;
	const profitErrorState = useGameValidationProfitError(GameType.PLINKO);
	const profitError_ =
		profitError !== undefined ? profitError : profitErrorState;

	const multipliers = useStateEffect(
		useCallback(() => getMultiplierByLinesQnt(lines_, risk_), [lines_, risk_]),
		(a, b) => transactionService.isListDifferent(a, b, ['label', 'value']),
	);

	return (
		<MultiplierBoxesStyled
			$count={multipliers.length}
			className="text-style-sm-regular"
		>
			<Tooltip
				onModal={onModal}
				localTheme="error"
				namespace={TransNamespace.COMMON}
				content={profitError_}
				isVisible={!!profitError_ && typeof profitError_ !== 'boolean'}
				hideOnClick={false}
			>
				<div>
					{multipliers.map((multiplier, index) => (
						<MultiplierBox
							enableAnimation={enableAnimation}
							key={`${risk}${lines}${multiplier.value}${index}`}
							multiplier={multiplier}
							index={index}
							count={multipliers?.length}
						/>
					))}
				</div>
			</Tooltip>
		</MultiplierBoxesStyled>
	);
};

export const MultiplierBoxesStyled = styled.div<{
	$count: number;
}>`
	display: block;
	width: 100%;

	span > div,
	> div {
		column-gap: 4px;
		display: grid;
		grid-template-columns: repeat(${(props) => props.$count}, 1fr);
		width: 100%;
	}
`;
