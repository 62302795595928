import styled from 'styled-components';
import { FC } from 'react';
import { LinkTo } from 'src/common/components/default/LinkTo/LinkTo';
import { generateSlotProviderUrl } from 'src/modules/slots/services/Slots.service';

interface SlotInfoProps {
	slotName: string;
	providerSlug: string;
	providerName: string;
}

export const StyledSlotInfo = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-top: 12px;
`;

export const StyledSlotInfoName = styled.div`
	color: var(--color-white);
	margin-bottom: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const SlotInfo: FC<SlotInfoProps> = ({
	slotName,
	providerSlug,
	providerName,
}) => {
	return (
		<StyledSlotInfo>
			<StyledSlotInfoName className="text-style-sm-medium">
				{slotName}
			</StyledSlotInfoName>
			<LinkTo
				href={generateSlotProviderUrl(providerSlug)}
				className="text-style-overline"
				style={{ color: 'var(--color-dark-200)' }}
			>
				{providerName}
			</LinkTo>
		</StyledSlotInfo>
	);
};
