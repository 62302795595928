import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { HiddenDataStyled } from '@ui/hidden-data/hidden-data.styled';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { UserLogin } from '@common/components/user/UserLogin/UserLogin';
import { formatToFullDate } from '@legacyApp/client/modules/app/timeService';
import { UserType } from '@legacyApp/types/user/UserType';
import { useAppMobile } from '@common/selectors/app.selectors';
import { GameIcon } from '../../../games/Game/components/Game.components/gameIcon';

export const BetDetails: FC<
	PropsWithChildren<{
		user: UserType;
		id: string;
		published_at: number;
		game: string;
		Icon?: React.ReactNode;
	}>
> = ({ user, id, published_at, Icon, game }) => {
	const isMobile = useAppMobile();
	const Icon_ = Icon || <GameIcon name={game} />;
	return (
		<BetDetailsStyled>
			<BetDetailsIdStyled className="text-overflow-ellipsis width-100">
				{isMobile ? (
					<span>{game && Icon_} </span>
				) : (
					<span className="text-style-sm-medium text-capitalize">
						{game && Icon_} {game}:
					</span>
				)}
				<span className="user-select-all break-all text-style-sm-regular">
					#{id}
				</span>
			</BetDetailsIdStyled>
			<div className="text-style-sm-regular">
				<span style={{ color: 'var(--color-dark-200)' }}>
					{trans({ label: 'Placed by' })}:{' '}
				</span>
				<span
					className="text-underline"
					style={{ color: 'var(--color-blue-dark)' }}
				>
					{user && <UserLogin user={user} />}
				</span>{' '}
				<span style={{ color: 'var(--color-dark-200)' }}>
					{trans({ label: 'at' })}
				</span>{' '}
				<span style={{ color: 'var(--color-blue-dark)' }}>
					{formatToFullDate(published_at * 1000)}
				</span>
			</div>
		</BetDetailsStyled>
	);
};

export const BetDetailsIdStyled = styled.div`
	img {
		height: 12px;
	}
`;

export const BetDetailsStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;

	${HiddenDataStyled} {
		display: inline-flex !important;
		position: relative;
		top: 3px;

		span {
			color: var(--color-blue-dark) !important;
		}

		svg {
			path {
				fill: var(--color-blue-dark);
			}
		}
	}
`;
