import styled, { css } from 'styled-components';
import { MultiplierBoxesStyled } from '../MuliplierBoxes/MultiplierBoxes';
import { MultiplierBoxStyled } from '../MuliplierBoxes/MultiplierBox';
import { Risk } from '../../types';
import { getBallColor } from '../playground/PixiJS/methods/getBallColor';

interface PlinkoGameBodyStyledProps {
	$resultIndex?: number;
	$risk?: Risk;
}

const BALL_SIZE = 15;
const BORDER_SIZE = 1;

const getMultipliersWidth = (props: PlinkoGameBodyStyledProps) => {
	const colors = getBallColor(props.$risk);
	return css`
		background-color: ${colors.fill};
		border: ${BORDER_SIZE}px solid ${colors.line};
	`;
};

export const PlinkoResultStyled = styled.div<PlinkoGameBodyStyledProps>`
	padding-bottom: 10px;
	padding-top: 40px;
	width: 100%;

	${MultiplierBoxesStyled} {
		bottom: unset;
		position: relative;
		width: 100%;

		${MultiplierBoxStyled} {
			opacity: 0.3;

			&:nth-child(${({ $resultIndex }) => $resultIndex + 1}) {
				opacity: 1;
				position: relative;

				&:before {
					${getMultipliersWidth};
					border-radius: 100%;
					content: '';
					height: ${BALL_SIZE}px;
					position: absolute;
					top: -${BALL_SIZE + BORDER_SIZE * 2}px;
					width: ${BALL_SIZE}px;
				}
			}
		}
	}

	@media (max-width: ${(props) => props.theme.media_v2.max_mobile_large}) {
		${MultiplierBoxesStyled} {
			${MultiplierBoxStyled} {
				&:nth-child(${({ $resultIndex }) => $resultIndex + 1}) {

					&:before {
						border-width: 2px;
						height: 10px;
						top: -14px;
						width: 10px;
					}
				}
			}
	}
`;
