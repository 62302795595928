import { Risk } from '../../../../types';

export const getBallColor = (risk: Risk) => {
	switch (risk) {
		case Risk.LOW:
			return {
				fill: '#0D6EFF',
				line: '#408BFC',
			};
		case Risk.MEDIUM:
			return {
				fill: '#c1a73d',
				line: '#c7ab7a',
			};
		case Risk.HIGH:
			return {
				fill: '#8548b2',
				line: '#7c5596',
			};
	}
};
