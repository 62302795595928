import styled, { css, keyframes } from 'styled-components';
import { FC, useCallback, useRef, useState } from 'react';
import { Sounds } from '@common/constants/config/sounds';
import { useWindowEventListener } from '@common/hooks/useEventListener';
import audioService from '../../../../../LegacyApp/client/modules/app/audioService';
import { PLINKO_RESULT_EVENT } from '../PinsLayer/constants';
import { MultiplierType } from '../../types';
import { PlinkoResultEventDetails } from '../../methods/plinkoTriggerEvents';
import { MultiplierColorCombinations } from './multipliers';

export interface MultiplierBoxProps {
	multiplier: MultiplierType;
	index: number;
	count: number;
	enableAnimation?: boolean;
}

export const MultiplierBox: FC<MultiplierBoxProps> = ({
	multiplier,
	index,
	count,
	enableAnimation,
}) => {
	const { value, risk, lines } = multiplier;
	const timeoutRef = useRef(null);
	const [active, setActive] = useState<boolean>(false);
	const onResult = useCallback(
		(event: CustomEvent<PlinkoResultEventDetails>) => {
			if (!enableAnimation) {
				return;
			}
			const { payoutIndex } = event?.detail;
			const isActivated = payoutIndex === index;
			if (!isActivated) {
				return;
			}
			audioService.play(Sounds.won, undefined, 0.8);
			setActive(isActivated);
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			timeoutRef.current = setTimeout(() => {
				setActive(null);
			}, 500);
		},
		[enableAnimation, index],
	);

	useWindowEventListener(PLINKO_RESULT_EVENT, onResult);

	return (
		<MultiplierBoxStyled
			key={`${risk}${lines}${value}${index}`}
			$index={index}
			$animation={active}
			$count={count}
			data-value={value}
		>
			{value < 10 && value >= 1 ? value.toFixed(1) : value}
			{Number(value) < 1000 && 'x'}
		</MultiplierBoxStyled>
	);
};

const getBoxColor = (index: number, count: number) => {
	return MultiplierColorCombinations?.[String(count)]?.[index];
};

export const MultiplierBoxStyled = styled.div<{
	$index: number;
	$count: number;
	$animation?: boolean;
}>`
	align-items: center;
	background-color: ${(props) => getBoxColor(props.$index, props.$count)};
	border-radius: 2px;
	color: var(--color-dark-900);
	display: flex;
	font-size: 10px;
	font-weight: bold;
	height: 31px;
	justify-content: center;
	line-height: 31px;
	position: relative;

	${(props) =>
		props.$animation &&
		css`
			animation: ${Blink} 0.5s ease-in-out 1;
		`};

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		font-size: 8px;
		height: 25px;
		line-height: 25px;
	}
`;

const Blink = (props) => keyframes`
    0% {
      box-shadow: 0 0 0 0 ${getBoxColor(props.$index, props.$count)};

    }
    49% {
      box-shadow: 0 0 3px 2px ${getBoxColor(props.$index, props.$count)};
			transform: translateY(6px);
		}
    50% {
      box-shadow: 0 0 3px 2px ${getBoxColor(props.$index, props.$count)};
			transform: translateY(6px);
    }
    100% {
      box-shadow: 0 0 0 0 ${getBoxColor(props.$index, props.$count)};
    }
`;
