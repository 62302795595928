import { IRendererOptions } from 'matter-js';
import { Mode } from './constants';

const labels = {
	ball: 'ball',
	floor: 'floor',
	pin: 'pin',
	wall: 'wall',
} as const;
export const colors = {
	background: 'transparent',
	pin: 'white',
} as const;
const scale = 1;
const pins = {
	startPins: 3,
	pinSize: 2 * scale,
	pinGap: 20 * scale,
	startToPinsSpace: 50 * scale,
};
const world = {
	width: 450 * scale,
	height: 450 * scale,
	element: 450,
};
const ball = {
	ballSize: 4.5 * scale,
	y: 2,
	maxX: world.width / 2 + pins.pinSize * (4 * scale) + pins.pinGap,
	minX: world.width / 2 - pins.pinSize * (4 * scale) - pins.pinGap,
};
const engine = {
	engineGravity: 2,
};
const renderer: {
	bounds: {
		max: {
			y: number;
			x: number;
		};
		min: {
			y: number;
			x: number;
		};
	};
	options: IRendererOptions;
} = {
	bounds: {
		max: {
			y: world.height,
			x: world.width,
		},
		min: {
			y: 0,
			x: 0,
		},
	},
	options: {
		showPositions: false,
		showPerformance: false,
		pixelRatio: 1,
		background: colors.background,
		hasBounds: true,
		width: world.width,
		height: world.height,
		wireframes: false,
	},
};

export const config = {
	mode: Mode.PIXI,
	pins,
	ball,
	engine,
	world,
	colors,
	scale,
	renderer,
	labels,
};
